import styled from 'styled-components'
import elderImg from 'assets/images/jpg/elder.jpg'

export const BackgroundStyled = styled.div`
  height: 450px;
  overflow: hidden;
  background: url(${elderImg});
  background-size: cover;
  background-position: center center;
  @media(min-width: 1114px) {
    height: 550px;
  }
`

export const ContentStyled  = styled.div`
  padding: 20px;
  max-width: 400px;
  position: relative;
  margin: 0 auto;
  left: 400px;
  right: 0;
  height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  @media(min-width: 1114px) {
    height: 550px;
  }
`