import React, { memo } from 'react'
import { withRouter } from 'react-router'
import LogoIcon from 'assets/images/svg/logo.svg'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import { Hidden } from 'components'
import { ContainerStyled, ToolbarStyled, AppBarStyled, LabelStyled, ContentLogoStyled } from './style'

function AppBar({ history }) {
  const goTo = (route) => {
    history.push(route)
  }

  return (
    <ContainerStyled>
      <AppBarStyled position="static">
        <ToolbarStyled>
          <Hidden smUp>
            <IconButton edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
          </Hidden>

          <Hidden smDown>
            <ContentLogoStyled>
              <img src={LogoIcon} height="48px" />
            </ContentLogoStyled>
            <LabelStyled onClick={() => goTo('/')}>
              Home
            </LabelStyled>
            <LabelStyled onClick={() => goTo('voluntario')}>
              Seja um voluntário
            </LabelStyled>
            <LabelStyled onClick={() => goTo('cadastro-idoso')}>
              Cadastro idoso
            </LabelStyled>
            <LabelStyled onClick={() => goTo('contribua-com-o-projeto')}>
              Contribua com o projeto
            </LabelStyled>
            <LabelStyled onClick={() => goTo('termos-de-uso')}>
              Termos de uso
            </LabelStyled>
            <LabelStyled onClick={() => goTo('contato')}>
              Contato
            </LabelStyled>
          </Hidden>
        </ToolbarStyled>
      </AppBarStyled>
    </ContainerStyled>
  )
}

export default memo(withRouter(AppBar))
