import React, { useState, memo } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { getTheme } from 'commons/utils/theme'

function ThemeProvider({ children }) {
  const [colorTheme, setTheme] = useState(null)
  const theme = getTheme(colorTheme)

  return (
    <MuiThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        {children({ setTheme })}
      </StyledThemeProvider>
    </MuiThemeProvider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.func.isRequired
}

export default memo(ThemeProvider)
