import React from 'react'
import { Router } from 'react-router-dom'
import { StylesProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import GlobalStyle from 'commons/styles/global-style'
import { history } from 'commons/utils/router'
import ThemeProvider from 'components/ThemeProvider'
import Main from './containers/Main'

const App = () => {
  return (
    <>
      <StylesProvider injectFirst>
        <ThemeProvider>
          {() => (
            <>
              <CssBaseline />
              <GlobalStyle />
              <Router history={history}>
                <Main />
              </Router>
            </>
          )}
        </ThemeProvider>
      </StylesProvider>
    </>
  )
}

export default App
