import React, { memo } from 'react'
import MeuBomAmigoLogo from 'assets/images/svg/logo.svg'
import { FooterStyled } from './style'

function Footer() {

  return (
    <FooterStyled>
      <img src={MeuBomAmigoLogo} alt="logo meu bom amigo" height="28px" />
      <span className="pl-2">
        MeuBomAmigo.om.br. Todos os direitos reservados.
      </span>
    </FooterStyled>
  )
}

export default memo(Footer)