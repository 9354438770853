import styled from 'styled-components'

export const FooterStyled = styled.footer`
  width: 100%;
  height: 80px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  display: block;
  padding: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
`
