import { lazy } from 'react'
import Layout from 'containers/Layout'

const HomeContainer = lazy(() => import('containers/Home'))
const RegisterElderly = lazy(() => import('containers/RegisterElderly'))
const ContributionContainer = lazy(() => import('containers/Contribution'))
const ContactContainer = lazy(() => import('containers/Contact'))
const TermsContainer = lazy(() => import('containers/Terms'))
const VoluntaryContainer = lazy(() => import('containers/Voluntary'))

const routes = [{
  path: '/',
  exact: true,
  component: HomeContainer,
  layout: Layout
},{
  path: '/cadastro-idoso',
  exact: true,
  component: RegisterElderly,
  layout: Layout
},{
  path: '/contribua-com-o-projeto',
  exact: true,
  component: ContributionContainer,
  layout: Layout
},{
  path: '/contato',
  exact: true,
  component: ContactContainer,
  layout: Layout
},{
  path: '/termos-de-uso',
  exact: true,
  component: TermsContainer,
  layout: Layout
},{
  path: '/voluntario',
  exact: true,
  component: VoluntaryContainer,
  layout: Layout
}]

const home = [
  ...routes
]

export default home
