import React from 'react'
import { TypographyStyled } from './style'

const Typography = (props) => <TypographyStyled {...props} />

Typography.defaultProps = {
  weight: 'normal',
  display: 'block',
  variant: 'body2'
}

export default Typography
