import React, { Suspense, memo } from 'react'
import { Switch, Redirect } from 'react-router-dom'
import routes from 'routes'
import AppRoute from './AppRoute'
import { AppBar, Footer } from 'components'
import { ContainerStyled } from './style'

const Main = () => (
  <ContainerStyled>
    <AppBar />
    <Suspense fallback={<div>carregando...</div>}>
      <Switch>
        {routes.map((route) => <AppRoute key={route.path} {...route} />)}
        <Redirect to="/" />
      </Switch>
    </Suspense>
    {/* <Footer /> */}
  </ContainerStyled>
)

export default memo(Main)
