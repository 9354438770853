import React, { memo } from 'react'
import { BackgroundStyled, ContentStyled } from './style'

function Outdoor({ component }) {

  return (
    <BackgroundStyled>
      <ContentStyled>
        {component}
      </ContentStyled>
    </BackgroundStyled>
  )
}

export default memo(Outdoor)