import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { LayoutStyled, ContainerStyled } from './style'

const Layout = ({ children }) => {

  return (
    <LayoutStyled>
      <ContainerStyled>
        {children}
      </ContainerStyled>
    </LayoutStyled>
  )
}

Layout.propTypes = {
  children: PropTypes.object
}

export default withRouter(Layout)
