import styled from 'styled-components'
import Typography from '../Typography'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'

export const ContainerStyled = styled.div`
  flex-grow: 1;
`

export const ToolbarStyled = styled(Toolbar)`
  @media(min-width: 769px) {
    min-width: 1140px;
  }
`

export const AppBarStyled = styled(AppBar)`
  background-color: #fff;

  @media(min-width: 769px) {
    overflow-x: auto;
    display: flex;
    align-items: center;
  }
`

export const LabelStyled = styled(Typography)`
  color: #000;
  font-weight: 400;
  flex-grow: 1;
  :hover {
    cursor: pointer;
  }
`

export const ContentLogoStyled = styled.div`
  padding-right: 40px;
`