import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

const DefaultLayout = ({ children }) => <Fragment>{children}</Fragment>

const AppRoute = ({ component: Component, layout: Layout, ...otherProps }) => {
  const renderLayout = (props) => (
    <Layout {...props}>
      <Component {...props} />
    </Layout>
  )

  return (
    <Route {...otherProps} render={(props) => renderLayout(props)} />
  )
}

DefaultLayout.propTypes = {
  children: PropTypes.node.isRequired
}

AppRoute.defaultProps = {
  layout: DefaultLayout
}

AppRoute.propTypes = {
  component: PropTypes.object.isRequired,
  layout: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.object])
}

export default AppRoute
