import styled from 'styled-components'

export const LayoutStyled = styled.div`
  width: 100%;
  min-height: 100%;
  height: auto;
  background: ${({ theme }) => theme.palette.background.level2};

`

export const ContainerStyled = styled.div`
  width: 100%;
  margin: 0 auto;
`