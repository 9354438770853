import React, { memo } from 'react'
import { SectionStyled } from './style'

function Section({ children, backgroundColor, ...otherProps }) {

  return (
    <SectionStyled backgroundColor={backgroundColor} {...otherProps}>
      {children}
    </SectionStyled>
  )
}

Section.defaultProps = {
  backgroundColor: 'primary'
}

export default memo(Section)
